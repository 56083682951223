import React, { useContext, useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Card, Table, Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "rc-pagination";
import { getServiceCityIdWisePincode, getActiveChallanState, updatePincodeStatus, deleteChallanState } from '../../service/apis';
import { SelectPicker } from 'rsuite';
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';

const ChallanServiceProvider = () => {
    const { userPermission} = useContext(AuthContext)

    const [current, setCurrent] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [allactiveChallanState, setAllActiveChallanState] = useState([]);
    const [limit, setLimit] = useState("25");

    useEffect(() => {
        fetchChallanState()
    }, [limit, current])

    const onChange = page => {
        setCurrent(page);
    };

    const data = ['25', '50', "100"].map(
        item => ({ label: item, value: item })
    );

    const fetchChallanState = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData()
            formData.append('limit', limit)
            formData.append('page', current)
            const response = await getActiveChallanState(formData)
            setAllActiveChallanState(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    try {
                        deleteChallanState(id).then(() => {
                            setCurrent(1)
                            fetchChallanState()
                            toast.success("Delete SuccessFully !")
                        })

                    } catch (error) {
                        console.log(error)
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };
    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3 className="my-1">Active Challan State</h3>
                    <div className="page-heading-right">
                        <SelectPicker
                            cleanable={false}
                            data={data}
                            searchable={false}
                            value={limit}
                            onChange={(e) => { setLimit(e); setCurrent(1) }}
                            className="wv-100 my-1 ms-3"
                            placeholder="Select Record"
                        />
                        {userPermission["Service-Provider"]?.add == 1 && <Link to="/affilation-challan-state/add" className="btn btn-primary my-1 ms-3">Add New</Link>
                        }                    </div>
                </div>
                <div className="page-content">
                    <Card>
                        <Card.Body className='position-relative'>
                            {isLoading && <div className="loader table-loader" ></div>}
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th width="5%" className="text-center">No</th>
                                        <th width="12%">Service Provider</th>
                                        <th width="61%">State Codes</th>
                                        <th width="8%" className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allactiveChallanState?.length > 0 && allactiveChallanState?.map((item, index) => {
                                        return (<tr key={index}>
                                            <td className="text-center">{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                                            <td>{item?.get_challan_services?.provider}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div> 
                                                        {item.state_codes && <div>{item.state_codes}</div>}   
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <Link to={`/affilation-challan-state/view/${item.id}`} className="btn btn-outline-warning btn-sm btn-icon me-2"><i className='bx bx-show'></i></Link>
                                                {userPermission["Service-Provider"]?.delete == 1 &&<Button variant="outline-danger" onClick={() => handleDelete(item?.id)} size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                                            }</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                            <div className="pagination-custom">
                                {allactiveChallanState?.data?.length > 0 && allactiveChallanState?.total_record > limit && <Pagination
                                    className="pagination-data"
                                    onChange={onChange}
                                    current={current}
                                    total={allactiveChallanState.total_record}
                                    pageSize={limit}
                                    showSizeChanger={false}
                                    showTitle={false}
                                />}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default ChallanServiceProvider