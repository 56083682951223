import React, { useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Form, Card, Row, Col, Button, Breadcrumb, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SelectPicker from 'rsuite/SelectPicker';
import Select from "react-select"
import { addServiceProviderCity, getServiceProviderDropdown, viewServiceProviderCity } from '../../service/apis';
import { toast } from "react-toastify";

const ServiceProviderCityAdd = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [idValidated, setIdValidated] = useState(false)
    const [isAllCity, setIsAllCity] = useState(true)
    const [serviceProviderCity, setServiceProviderCity] = useState([])
    const [serviceProviderDropdownCity, setServiceProviderDropdownCity] = useState([])
    const [selectedCitys, setSelectedCitys] = useState([])
    const [selectedStates, setSelectedState] = useState([])
    const [isQueryMaping, setIsQueryMaping] = useState(0)
    const [queryId, setQueryId] = useState({ 0: [], 1: [] })
    // const [queryMapingData, setQueryMapingData] = useState({})
    const [selectedQueryMapingData, setSelectedQueryMapingData] = useState({})
    const [validation, setValidation] = useState({
        service_provider_id: "Please Select Service ProviderId",
        affiliation_services_id: "Please Select Service",
        cities: "Please Select City",
    })

    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        fetchServiceProviderDropdown()
    }, [])
    useEffect(() => {
        params.id && fetchViewServiceProviderCity()
    }, [params?.id])


    //Form Field
    const serviceProvider = serviceProviderDropdownCity?.service_provider?.map(
        item => ({ label: item?.provider, value: item?.id })
    );
    const service = serviceProviderDropdownCity?.affilation_service?.map(
        item => ({ label: item?.services, value: item?.id })
    );
    const citys = serviceProviderDropdownCity?.affilation_city?.map(
        item => ({ label: item?.city, value: item?.id })
    );
    const states = serviceProviderDropdownCity?.affilation_state?.map(
        item => ({ label: item?.state, value: item?.state })
    );

    useEffect(() => {
        if (serviceProviderCity?.city_name) {
            const updatedVehicleName = serviceProviderCity?.city_name?.map((item) => ({
                label: item.city,
                value: item?.id
            }));

            setSelectedCitys((prevVehicleName) => [...updatedVehicleName]);
        }
    }, [serviceProviderCity?.city_name]);

    //api 
    const fetchServiceProviderDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getServiceProviderDropdown()
            setServiceProviderDropdownCity(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchViewServiceProviderCity = async () => {
        setIsLoading(true);
        try {
            const response = await viewServiceProviderCity(params.id)
            setIsQueryMaping(response?.data?.data?.is_mapping)
            setServiceProviderCity(response?.data?.data)
            const mappingKey = response?.data?.data?.is_mapping;
            const queryParams = response?.data?.data?.query_params && response?.data?.data?.query_params?.split(",")?.map(Number);
            setQueryId((prev) => ({
                ...prev,
                [mappingKey]: queryParams
            }));
            setIsAllCity(response?.data?.data?.is_all_city)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        const filterList = serviceProviderDropdownCity?.queryData
            ?.filter(item => serviceProviderCity?.query_params?.includes(item.id))
            .map(item => item.query_name);

        const obj = serviceProviderCity?.mapping_data
            ?.filter(item => filterList?.includes(item.query_name))
            .reduce((acc, item) => {
                acc[item.query_name] = item.alter_query_name;
                return acc;
            }, {});
        setSelectedQueryMapingData(obj)
    }, [serviceProviderDropdownCity, serviceProviderCity])
    //on change
    const handleChange = (e, name) => {
        const updatedData = { ...serviceProviderCity };
        if (name == "pincode_id") {
            const input = e;
            const regex = /^[0-9,]+$/;
            if (regex.test(input) || input === "") {
                updatedData[name] = e
            }
        } else {

            updatedData[name] = e
        }

        setServiceProviderCity(updatedData)
        setValidation({ ...validation, [name]: "" })
        // setIdValidated(true)
    }
    const handleSelectCitys = (e) => {
        const city = []
        e.map((item) => city.push({ city: item?.label, id: item?.value }))
        const updatedData = { ...serviceProviderCity };
        updatedData.city_name = city
        setServiceProviderCity(updatedData)
        city.length > 0 ? setValidation({ ...validation, cities: "" }) : setValidation({ ...validation, cities: "Please Select City" });
    }
    useEffect(() => {
        if (serviceProviderCity?.city_name) {
            const updatedVehicleName = serviceProviderCity?.city_name?.map((item) => ({
                label: item.city,
                value: item?.id
            }));
            const allCityId = serviceProviderCity?.city_name.map((s) => s.id)
            if (serviceProviderDropdownCity && serviceProviderDropdownCity.affilation_city) {
                const selectedState = [...new Set(serviceProviderDropdownCity?.affilation_city?.filter(item => allCityId.includes(item.id))?.map(item => item.state))].map((item) => ({ label: item, value: item }));
                setSelectedState(selectedState)

            }
            setSelectedCitys((prevVehicleName) => [...updatedVehicleName]);
        }
    }, [serviceProviderCity?.city_name, serviceProviderDropdownCity]);

    const findDifferences = (fi, f1) => {
        return f1.filter(itemF1 => !fi.some(itemFi => itemFi.value === itemF1.value));
    };

    const handleSelectStates = (e) => {
        const city = [];
        if (e > selectedStates) {
            const diff = findDifferences(selectedStates, e);
            const stateNames = diff.map((s) => s.value);
            const allStateWiseCity = serviceProviderDropdownCity?.affilation_city?.filter((s) => stateNames.includes(s.state));
            if (serviceProviderCity?.city_name?.length) {
                serviceProviderCity.city_name.forEach((item) => city.push({ city: item?.city, id: item?.id }));
            }
            allStateWiseCity.forEach((item) => city.push({ city: item?.city, id: item?.id }));
            const updatedData = { ...serviceProviderCity, city_name: city };
            setServiceProviderCity(updatedData);

        } else {
            const diff = findDifferences(e, selectedStates);
            const stateNames = diff.map((s) => s.value);
            const allStateWiseCity = serviceProviderDropdownCity?.affilation_city?.filter((s) => stateNames.includes(s.state));
            const allStateId = allStateWiseCity.map((s) => s.id);
            const updatedData = {
                ...serviceProviderCity,
                city_name: serviceProviderCity.city_name.filter(city => !allStateId.includes(city.id))
            };
            setServiceProviderCity(updatedData);
        }

        setSelectedState(e);
    };


    const handleSave = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (!form.checkValidity()) {
            event.stopPropagation();
            setIdValidated(true);
            return;
        }
        setIdValidated(true);

        const { service_provider_id, affiliation_services_id, pincode_id, city_name, redirect_url } = serviceProviderCity;
        const checkStatus = isAllCity || (selectedCitys && selectedCitys.length > 0);

        if (service_provider_id && affiliation_services_id && checkStatus) {
            setIsLoading(true);

            try {
                const formData = new FormData();
                formData.append("city_id_lable", isAllCity ? "All" : "Specify City");
                formData.append("service_provider_id", service_provider_id);
                formData.append("affiliation_services_id", affiliation_services_id);
                formData.append("redirect_url", redirect_url);
                formData.append("query_params", queryId[isQueryMaping]?.toString());
                formData.append("is_mapping", isQueryMaping);
                isQueryMaping == 1 && formData.append("mappingArray", JSON.stringify(selectedQueryMapingData ? selectedQueryMapingData : {}));
                pincode_id && formData.append("block_pincode_list", pincode_id?.replace(/,$/, ''));
                !isAllCity && formData.append("city_id_list", JSON.stringify(city_name));
                params.id && formData.append("id", params.id);

                const res = await addServiceProviderCity(formData);
                if (res?.data?.status) {
                    toast.success(`Service Provider City ${res?.data?.message}`);
                } else {
                    toast.error(`Service Provider City ${res?.data?.message}`);
                }
                navigate(params?.id ? `/service-provider-city/view/${params.id}` : "/service-provider-city");
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleChangeQuery = (e) => {
        setSelectedQueryMapingData({ ...selectedQueryMapingData, [e.target.name]: e.target.value })
    }
    const handleCheckQuery = (item) => {
        setQueryId((prev) => {
            const currentArray = prev[isQueryMaping] || [];
            if (currentArray.includes(item.id)) {
                return {
                    ...prev,
                    [isQueryMaping]: currentArray.filter((e) => e !== item.id)
                };
            } else {
                return {
                    ...prev,
                    [isQueryMaping]: [...currentArray, item.id]
                };
            }
        });

        isQueryMaping == 1 && setSelectedQueryMapingData((prev) => {
            const updatedPrev = { ...prev };

            if (Object.keys(updatedPrev).includes(item?.query_name)) {
                delete updatedPrev[item.query_name];
            } else {
                updatedPrev[item.query_name] = item.query_name;
            }
            return updatedPrev;
        });

    };

    return (
        <>
            <Layout sidebar={true}>
                {/* {isLoading && <div className="loader" ></div>} */}
                <div className="page-heading">
                    <h3>Service Provider City {params.id ? "Edit" : "Add"}</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/service-provider-city">Service Provider City</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Service Provider City {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Form noValidate validated={idValidated} onSubmit={handleSave}>
                        <Card className="radiustop-0">
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label>Services Provider</Form.Label>
                                        <SelectPicker
                                            cleanable={false}
                                            data={serviceProvider}
                                            onChange={(e) => handleChange(e, "service_provider_id")}
                                            value={serviceProviderCity?.service_provider_id && serviceProviderCity?.service_provider_id}
                                            name='service_provider_id'
                                            block

                                            className={`my-2 ${(idValidated && !serviceProviderCity?.service_provider_id) && "rs-novalidated"}`}
                                            placeholder="Select Services Provider"
                                        />
                                        {(idValidated && !serviceProviderCity?.service_provider_id) && <div className='rs-invalid-feedback'>
                                            Select Services Provider
                                        </div>}
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label>Services</Form.Label>
                                        <SelectPicker
                                            cleanable={false}
                                            data={service}
                                            onChange={(e) => handleChange(e, "affiliation_services_id")}
                                            value={serviceProviderCity?.affiliation_services_id && serviceProviderCity?.affiliation_services_id}

                                            block
                                            className={`my-2 ${(idValidated && !serviceProviderCity?.affiliation_services_id) && "rs-novalidated"}`}
                                            placeholder="Select Services"
                                        />
                                        {(idValidated && !serviceProviderCity?.affiliation_services_id) && <div className='rs-invalid-feedback'>
                                            Select Services
                                        </div>}
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>City Key</Form.Label>
                                        <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "city_key")} className='my-2' />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Pincode Key</Form.Label>
                                        <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "pincode_key")} className='my-2' />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Language Key</Form.Label>
                                        <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "language_key")} className='my-2' />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Redirect URL </Form.Label>
                                        <Form.Control type='text'
                                            onChange={(e) => handleChange(e.target.value, "redirect_url")}
                                            value={serviceProviderCity?.redirect_url && serviceProviderCity?.redirect_url}
                                            className='my-2'
                                            required />
                                    </Col>
                                    <Col md={12}>
                                        <Row className='querymaping'>
                                            <Col md={8} className='my-2'>
                                                <Form.Label className='me-2'>Query Maping : </Form.Label>
                                                <Form.Check inline label="Yes" onChange={() => setIsQueryMaping(1)} name="querymaping" type="radio" id='yes' checked={isQueryMaping == 1} />
                                                <Form.Check inline label="No" onChange={() => setIsQueryMaping(0)} name="querymaping" type="radio" id='no' checked={isQueryMaping == 0} />
                                                <Row className='gy-2 mt-2 align-items-center'>
                                                    {serviceProviderDropdownCity?.queryData?.length > 0 && serviceProviderDropdownCity?.queryData?.map((item, index) => {
                                                        return (<Col md={isQueryMaping == 1 ? 4 : 3} key={index}>
                                                            <InputGroup>
                                                                <InputGroup.Text>
                                                                    <Form.Check className='mb-0' type="checkbox" onClick={() => handleCheckQuery(item)} checked={queryId[isQueryMaping]?.includes(item?.id)} label={item?.query_name} />
                                                                </InputGroup.Text>
                                                                {isQueryMaping == 1 &&
                                                                    < Form.Control name={item?.query_name} onChange={handleChangeQuery}
                                                                        placeholder="Enter Alter Query Mapping Value"
                                                                        // value={queryMapingData ? queryMapingData[item?.query_name] : ""}
                                                                        value={selectedQueryMapingData && Object.keys(selectedQueryMapingData).includes(item?.query_name) ? selectedQueryMapingData[item?.query_name] : item?.query_name}
                                                                        type='text' disabled={!queryId[isQueryMaping]?.includes(item?.id)} />}
                                                            </InputGroup>
                                                        </Col>)
                                                    })}
                                                </Row>
                                            </Col>
                                            <Col md={4} className='my-2'>
                                                <Form.Label className='me-2'>City Name : </Form.Label>
                                                <Form.Check inline label="All City" name="cityname" type="radio" id='city1' onChange={(e) => { setIsAllCity(true); }} checked={isAllCity} />
                                                <Form.Check inline label="Specify City" name="cityname" type="radio" id='city2' onChange={(e) => { setIsAllCity(false); setSelectedCitys([]); setSelectedState([]); setValidation({ ...validation, cities: "Please Select City" }) }} checked={!isAllCity} />

                                                {!isAllCity && <Select
                                                    closeMenuOnSelect={false}
                                                    name="state_name"
                                                    isDisabled={isAllCity}
                                                    value={selectedStates}
                                                    onChange={(e) => handleSelectStates(e)}
                                                    isClearable={true}
                                                    placeholder="Select State"
                                                    className={`customMulSelect my-2 ${(idValidated && !isAllCity && !selectedStates.length) && "rs-novalidated"}`}
                                                    classNamePrefix="react-select"
                                                    isMulti
                                                    options={states}
                                                    required
                                                />}
                                                {(idValidated && !isAllCity && !selectedStates.length) && <div className='rs-invalid-feedback'>
                                                    Select State
                                                </div>}

                                                {!isAllCity && <Select
                                                    closeMenuOnSelect={false}
                                                    name="city_name"
                                                    isDisabled={isAllCity}
                                                    value={selectedCitys}
                                                    onChange={(e) => handleSelectCitys(e)}
                                                    isClearable={true}
                                                    placeholder="Select City"
                                                    className={`customMulSelect my-2 ${(idValidated && !isAllCity && !selectedCitys.length) && "rs-novalidated"}`}
                                                    classNamePrefix="react-select"
                                                    isMulti
                                                    options={citys}
                                                    required
                                                />}
                                                {(idValidated && !isAllCity && !selectedCitys.length) && <div className='rs-invalid-feedback'>
                                                    Select City
                                                </div>}
                                                {!isAllCity &&
                                                    <div className='my-2'>
                                                        <Form.Label>Block Pincode List</Form.Label>
                                                        <Form.Control type='text' onChange={(e) => handleChange(e.target.value, "pincode_id")} value={serviceProviderCity?.pincode_id} className='my-2' />
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" type='submit'>Save</Button>
                                <Link to='/service-provider-city' className="btn btn-secondary">Cancel</Link>
                            </Card.Footer>
                        </Card>
                    </Form>
                </div>
            </Layout>
        </>
    )
}

export default ServiceProviderCityAdd