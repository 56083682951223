import React, { useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Form, Card, Row, Col, Button, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SelectPicker from 'rsuite/SelectPicker';
import Select from "react-select"
import { addServiceProviderChallanState, getServiceProviderDropdown, viewChallanState } from '../../service/apis';
import { toast } from "react-toastify";

const ChallanServiceStateAdd = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [idValidated, setIdValidated] = useState(false)
    const [isChallanState, setChallanState] = useState(true)
    const [serviceProviderCity, setServiceProviderCity] = useState([])
    const [serviceProviderState, setServiceProviderState] = useState([])
    const [selectedStates, setSelectedState] = useState([])
    const [serviceProviderDropdownCity, setServiceProviderDropdownCity] = useState([])
    const [selectedCitys, setSelectedCitys] = useState([])
    const [validation, setValidation] = useState({
        service_provider_id: "Please Select Service ProviderId",
        affiliation_services_id: "Please Select Service",
        cities: "Please Select City",
    })
   
    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        fetchServiceProviderDropdown()
    }, [])
    useEffect(() => {
        params.id && fetchViewChallanState()
    }, [params?.id])


    const serviceProvider = serviceProviderDropdownCity?.service_provider?.map(
        item => ({ label: item?.provider, value: item?.id })
    );
    const service = serviceProviderDropdownCity?.affilation_service?.map(
        item => ({ label: item?.services, value: item?.id })
    );
    const citys = serviceProviderDropdownCity?.affilation_city?.map(
        item => ({ label: item?.city, value: item?.id })
    );

    useEffect(() => {
        if (serviceProviderCity?.city_name) {
            const updatedVehicleName = serviceProviderCity?.city_name?.map((item) => ({
                label: item.city,
                value: item?.id
            }));

            setSelectedCitys((prevVehicleName) => [...updatedVehicleName]);
        }
    }, [serviceProviderCity?.city_name]);

    //api 
    const fetchServiceProviderDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getServiceProviderDropdown()
            setServiceProviderDropdownCity(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchViewChallanState = async () => {
        setIsLoading(true);
        try {
            const response = await viewChallanState(params.id)
            setServiceProviderState(response?.data?.data)
            if (response?.data?.data?.state_codes) {
                const stateCodes = response?.data?.data?.state_codes.split(',').map(item => ({ label: item, value: item }))
                setSelectedState(stateCodes)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    //on change
    const handleChange = (e, name) => {
        const updatedData = { ...serviceProviderCity };
        updatedData[name] = e
        setServiceProviderState(updatedData)
        setValidation({ ...validation, [name]: "" })
        // setIdValidated(true)
    }
    const handleSelectState = (e) => {
        setSelectedState(e)
    }
    const handleSave = (event) => {
        event.preventDefault();
        setIdValidated(true)
        if (
            (serviceProviderState?.service_provider_id &&
                selectedStates.length)
        ) {
            setIsLoading(true);
            try {
                const formData = new FormData()
                formData.append("service_provider_id", serviceProviderState?.service_provider_id);
                formData.append("states_list", selectedStates.map(item => item.label));
                params.id && formData.append("id", params.id);
                addServiceProviderChallanState(formData).then(() => { toast.success(`Challan State ${params?.id ? "Update Successfuly" : "Add Successfuly"}`); { params?.id ? navigate(`/affilation-challan-state/view/${params.id}`) : navigate("/affilation-challan") } })
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false);
            }

        } else {
            toast.error("Please fill all field !")
        }
    }

    const stateArray = ['AN', 'AP', 'AR', 'AS', 'BR', 'CH', 'CG', 'DD', 'DN', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KR', 'KL', 'LA', 'LD', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OD', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB'];

    const formattedArray = stateArray.map(state => ({
        value: state,
        label: state
    }));
    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3>Active Challan State {params.id ? "Edit" : "Add"}</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/affilation-challan">Affilation Challan</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Affilation Challan State {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Row className="g-4">
                        <Col sm={12}>
                            <Form noValidate validated={idValidated} onSubmit={handleSave}>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Label>Services Provider</Form.Label>
                                                <SelectPicker
                                                    cleanable={false}
                                                    data={serviceProvider}
                                                    onChange={(e) => handleChange(e, "service_provider_id")}
                                                    value={serviceProviderState?.service_provider_id && serviceProviderState?.service_provider_id}
                                                    name='service_provider_id'
                                                    block
                                                    className={`my-2 ${(idValidated && !serviceProviderState?.service_provider_id) && "rs-novalidated"}`}
                                                    placeholder="Select Services Provider"
                                                />
                                                {(idValidated && !serviceProviderState?.service_provider_id) && <div className='rs-invalid-feedback'>
                                                    Select Services Provider
                                                </div>}
                                            </Col>
                                            <Col md={6}>
                                                <Form.Label className='me-2'>State Codes</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    name="state_name"
                                                    value={selectedStates}
                                                    onChange={(e) => handleSelectState(e)}
                                                    isClearable={true}
                                                    placeholder="Select State"
                                                    className={`customMulSelect my-2 ${(idValidated && !selectedStates.length) && "rs-novalidated"}`}
                                                    classNamePrefix="react-select"
                                                    isMulti
                                                    options={formattedArray}
                                                    required
                                                />
                                                {(idValidated && !selectedStates.length) && <div className='rs-invalid-feedback'>
                                                    Select State
                                                </div>}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="text-end">
                                        <Button variant="primary" className="me-3" type='submit'>Save</Button>
                                        <Link to='/service-provider-city' className="btn btn-secondary">Cancel</Link>
                                    </Card.Footer>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    )
}

export default ChallanServiceStateAdd