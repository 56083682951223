import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';
import { AuthContext } from '../AppContext';

const Sidebar = ({ sidebar }) => {
    const { classActive, setClassActive, userPermission } = useContext(AuthContext)
    const roleName = localStorage.getItem('role') ? localStorage.getItem('role') : ""
    const handleActiveClass = (name) => {
        let data = {
            drp1: false,
            drp2: false,
            drp3: false,
            drp4: false,
            drp5: false,
            drp6: false,
            drp7: false,
        }
        data[name] = classActive[name] === true ? false : true
        setClassActive(data)
    };
    return (
        <>
            <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
                <div className="sidebar-header">
                    <div className="d-flex justify-content-between">
                        <div className='sidebar-logo'>
                            <Link to="/Home">
                                <Logo />
                                <LogoMini />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-item">
                            <NavLink to="/Home" className='sidebar-link'>
                                <i className='bx bxs-home'></i>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        {(userPermission["Affilation-Dashboard"]?.view == 1 || userPermission["Affilation-Program"]?.view == 1 || userPermission["Service-City"]?.view == 1 || userPermission["Service-Provider"]?.view == 1) && <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp1") }} className="sidebar-link">
                                <i className='bx bxs-group'></i>
                                <span>Affiliation</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp1 ? "active" : "inactive"}`} >
                                {userPermission["Affilation-Dashboard"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affiliation-dashboard">
                                        <span>Affiliation Dashboard</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Affilation-Program"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affiliation">
                                        <span>Affiliation Program</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-City"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affiliation-city">
                                        <span>All Services City List</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-Provider"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/service-provider-city">
                                        <span>Services Provider City</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Affilation-Program"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/change-url">
                                        <span>Change URL</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Service-Provider"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affilation-challan">
                                        <span>Challan </span>
                                    </NavLink>
                                </li>
                                }
                            </ul>
                        </li>}
                        {(userPermission["Affiliation-Clicks"]?.view == 1 || userPermission["Affiliation-Performance"]?.view == 1) && <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp2") }} className="sidebar-link">
                                <i className='bx bxs-spreadsheet'></i>
                                <span>Affili. Campaign</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp2 ? "active" : "inactive"}`} >
                                {userPermission["Affiliation-Clicks"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/affiliation-tracker">
                                        <span>Clicks</span>
                                    </NavLink>
                                </li>}
                                {userPermission["Affiliation-Performance"]?.view == 1 && <li className="submenu-item">
                                    <NavLink to="/tracker-performance">
                                        <span>Performance</span>
                                    </NavLink>
                                </li>}
                            </ul>
                        </li>}
                        {roleName == "ADMIN" ? <li className="sidebar-item has-sub">
                            <div onClick={() => { handleActiveClass("drp3") }} className="sidebar-link">
                                <i className='bx bxs-lock-open-alt' ></i>
                                <span>User Permission</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp3 ? "active" : "inactive"}`} >
                                <li className="submenu-item">
                                    <NavLink to="/module">
                                        <span>Module</span>
                                    </NavLink>
                                </li>
                                <li className="submenu-item">
                                    <NavLink to="/role">
                                        <span>Role</span>
                                    </NavLink>
                                </li>
                                <li className="submenu-item">
                                    <NavLink to="/user">
                                        <span>Permission</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li> : ""}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar