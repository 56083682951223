import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import SelectPicker from "rsuite/SelectPicker";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  getMakeModalDropDown,
  getServiceProviderDropdown,
} from "../service/apis";
const rsdata = ["InsuranceDekho", "Acko", "Godigit"].map((item) => ({
  label: item,
  value: item,
}));
const rsoptions = [
  { value: "High", label: "High" },
  { value: "Medium", label: "Medium" },
  { value: "Low", label: "Low" },
];
const vehicleTypeDropdown = [
  { value: "Private", label: "Private" },
  { value: "Commercial", label: "Commercial" },
]

const date = new Date();
const CurrentYear = date.getFullYear();
let manufactureYear = [];
for (let i = 1990; i <= Number(CurrentYear); i++) {
  const item = { value: i.toString(), label: i.toString() };
  manufactureYear.push(item);
}

function AddAffilation({ handleTestOnChange, modalDropDown, makerDropDown, allAffilationData, handleDelete, handleDefault, defaultValue, validated, setAllAffilationData, isSubmited }) {
  // const [modalDropDown, setmodalDropDown] = useState({ bike: [], car: [] });
  // const [makerDropDown, setmakerDropDown] = useState({ bike: [], car: [] });
  const [selectedStates, setSelectedStates] = useState({});
  const [serviceProviderDropdownCity, setServiceProviderDropdownCity] = useState([]);
  const bikeCalled = useRef(false);
  const carCalled = useRef(false);

  // const getMakemodalDropDownBikeList = async (vehicleCategoryId) => {
  //   try {
  //     const response = await getMakeModalDropDown(vehicleCategoryId);
  //     const modal = response?.data?.data?.models?.map((item) => {
  //       return { value: item?.name, label: item?.name };
  //     });
  //     const makers = response?.data?.data?.makers?.map((item) => {
  //       return { value: item?.name, label: item?.name };
  //     });
  //     if (vehicleCategoryId == 1) {
  //       setmodalDropDown(prev => { return { ...prev, bike: [...modal] } })
  //       setmakerDropDown(prev => { return { ...prev, bike: [...makers] } })
  //     }
  //     else if (vehicleCategoryId == 2) {
  //       setmodalDropDown(prev => { return { ...prev, car: [...modal] } })
  //       setmakerDropDown(prev => { return { ...prev, car: [...makers] } })
  //     }
  //   } catch (err) {
  //     console.log(err, "error in dropdown list fetch");
  //   }
  // }

  const fetchServiceProviderDropdown = async () => {
    try {
      const response = await getServiceProviderDropdown();
      setServiceProviderDropdownCity(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const serviceProvider = serviceProviderDropdownCity?.service_provider?.map(
    (item) => ({ label: item?.provider, value: item?.id })
  );

  const service = serviceProviderDropdownCity?.affilation_service?.map(
    (item) => ({ label: item?.services, value: item?.id })
  );

  const citys = serviceProviderDropdownCity?.affilation_city?.map((item) => ({
    label: item?.city,
    value: item?.id,
    state: item?.state
  }));

  const states = serviceProviderDropdownCity?.affilation_state?.map((item) => {
    return {
      label: item.state,
      value: item.state,
    }
  })

  const handleServiceChange = (value, index, field, prev) => {
    setAllAffilationData((prevData) => {
      return prevData?.map((item, i) => {
        if (i == index) {
          let findLabel = service?.find(item => item.value == value)?.label || null;
          let valueString = findLabel?.toString().toLowerCase();
          let is_bikeOrCar = valueString?.includes('bike') ? 1 : valueString?.includes('car') ? 2 : '';
          item.is_bikeOrCar = is_bikeOrCar
          // if (is_bikeOrCar == 1 && modalDropDown?.bike?.length == 0 && makerDropDown?.bike?.length == 0) {
          //   getMakemodalDropDownBikeList(1)
          //   return { ...item, [field]: value }
          // }
          // else if (is_bikeOrCar == 2 && modalDropDown?.car?.length == 0 && makerDropDown?.car?.length == 0) {
          //   getMakemodalDropDownBikeList(2)
          //   return { ...item, [field]: value }
          // }
          // else {
          if (prev == is_bikeOrCar) {
            return { ...item, [field]: value }
          } else {
            return { ...item, [field]: value, maker: '', model: '' }
          }
          // }


        }
        else return item
      })
    })
  }

  useEffect(() => {
    fetchServiceProviderDropdown();
  }, []);
  useEffect(() => {
    let affilationData = allAffilationData?.map((ele) => {
      let findLabel = service?.find(item => item?.value == ele?.services)?.label || null;
      let valueString = findLabel?.toString().toLowerCase();
      let is_bikeOrCar = valueString?.includes('bike') ? 1 : valueString?.includes('car') ? 2 : '';
      ele.is_bikeOrCar = is_bikeOrCar;

      ele.vehicle_age.greater_than_equal = ele?.vehicle_age?.greater_than_equal == 0 ? '' : ele?.vehicle_age?.greater_than_equal;
      ele.vehicle_age.less_than_equal = ele?.vehicle_age?.less_than_equal == 0 ? '' : ele?.vehicle_age?.less_than_equal;

      ele.expiry_days.greater_than_equal = ele?.expiry_days?.greater_than_equal == 0 ? '' : ele?.expiry_days?.greater_than_equal;
      ele.expiry_days.less_than_equal = ele?.expiry_days?.less_than_equal == 0 ? '' : ele?.expiry_days?.less_than_equal;

      ele.expired_days.greater_than_equal = ele?.expired_days?.greater_than_equal == 0 ? '' : ele?.expired_days?.greater_than_equal;
      ele.expired_days.less_than_equal = ele?.expired_days?.less_than_equal == 0 ? '' : ele?.expired_days?.less_than_equal;

      // if (is_bikeOrCar == 1 && !bikeCalled.current && (modalDropDown?.bike?.length == 0 && makerDropDown?.bike?.length == 0)) {
      //   getMakemodalDropDownBikeList(1)
      //   bikeCalled.current = true;
      //   // return ele
      // }
      // else if (is_bikeOrCar == 2 && !carCalled.current && (modalDropDown?.car?.length == 0 && makerDropDown?.car?.length == 0)) {
      //   getMakemodalDropDownBikeList(2)
      //   carCalled.current = true;
      //   // return ele
      // }
      return ele
    })
    setAllAffilationData(affilationData)
  }, [serviceProviderDropdownCity, isSubmited])

  const handleStateChange = (e, i, oldState) => {
    const stateData = e?.map((s) => s.value.toLowerCase()) || [];
    let existedCity = allAffilationData[i]?.city_id_list
      ?.split(',')
      ?.map(Number) || [];

    if (stateData.length > oldState.length) {
      const newStates = stateData.filter(
        (state) => !oldState.some((old) => old?.value?.toLowerCase() === state)
      );

      newStates.forEach((newState) => {
        const matchingCities = citys
          .filter((cityObj) => cityObj?.state?.toLowerCase() === newState)
          .map((cityObj) => cityObj?.value);

        existedCity = [...existedCity, ...matchingCities];
      });
    } else {
      // Removing states
      const removedState = oldState.find(
        (old, index) => old?.value?.toLowerCase() !== stateData[index]
      )?.value?.toLowerCase();

      if (removedState) {
        const removeStateCities = citys
          .filter((cityObj) => cityObj?.state?.toLowerCase() === removedState)
          .map((cityObj) => cityObj?.value);

        existedCity = existedCity.filter(
          (city) => !removeStateCities.includes(city)
        );
      } else if (stateData.length === 0) {
        existedCity = [];
      }
    }

    setSelectedStates((prev) => ({
      ...prev,
      [i]: e,
    }));

    setAllAffilationData((prev) =>
      prev.map((ele, ind) =>
        ind === i
          ? { ...ele, city_id_list: existedCity.join(',') }
          : ele
      )
    );
  };

  return (
    <>
      {allAffilationData?.length > 0 &&
        allAffilationData?.map((el, i) => {

          let is_bikeOrCar = '';
          let findLabel = service?.find(item => item?.value == el?.services)?.label || null;
          let valueString = findLabel?.toString().toLowerCase();
          is_bikeOrCar = valueString?.includes('bike') ? 1 : valueString?.includes('car') ? 2 : '';
          el.is_bikeOrCar = is_bikeOrCar

          let selectedState = selectedStates[i] || [];
          const allCityId = el?.city_id_list?.split(",");

          if (citys) {
            selectedState = [...new Set(citys?.filter(item => allCityId?.includes(item?.value?.toString()))?.map(item => item?.state))].map((item) => ({ label: item, value: item }));
          }

          return (
            <Form id="affiliation" key={i} noValidate validated={validated}>
              <Col sm={12}>
                <Card className="border">
                  <Card.Body>
                    <Row>
                      <Col lg={3} md={4}>
                        <Form.Label>Services Provider</Form.Label>
                        <SelectPicker
                          cleanable={false}
                          data={serviceProvider}
                          block
                          value={el?.service_provider || ''}
                          onChange={(e) =>
                            handleTestOnChange(e, i, "service_provider")
                          }
                          className="my-2"
                          placeholder="Select Services Provider"
                        />
                        {!el?.service_provider && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a service provider.
                          </div>
                        )}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Services</Form.Label>
                        <SelectPicker
                          cleanable={false}
                          data={service}
                          block
                          value={el?.services || ''}
                          className="my-2"
                          onChange={(e) => {
                            // handleTestOnChange(e, i, "services");
                            handleServiceChange(e, i, 'services', el?.is_bikeOrCar)
                          }}
                          placeholder="Select Services"
                        />
                        {
                          el?.services && !el?.is_bikeOrCar && (
                            <div className="rs-invalid-feedback">
                              Please select a valid service.
                            </div>
                          )
                        }
                        {!el?.services && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a service.
                          </div>
                        )}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Priority</Form.Label>
                        <SelectPicker
                          cleanable={false}
                          data={rsoptions}
                          block
                          value={el?.priority?.toString()}
                          onChange={(e) => handleTestOnChange(e, i, "priority")}
                          className="my-2"
                          placeholder="Select Priority"
                        />
                        {!el?.priority && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a priority.
                          </div>
                        )}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Vehicle Type</Form.Label>
                        <Select
                          cleanable={false}
                          options={vehicleTypeDropdown}
                          block
                          value={el?.vehicle_type && vehicleTypeDropdown?.filter((e) => el?.vehicle_type?.split(",")?.some((id) => id == e.value))}
                          onChange={(e) => handleTestOnChange(e, i, "vehicle_type")}
                          isMulti
                          className={`customMulSelect my-2`}
                          classNamePrefix="react-select"

                          placeholder="Select Vehicle Type"
                        />
                        {/* {!el?.vehicle_type && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a Vehicle Type.
                          </div>
                        )} */}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Vehicle Age</Form.Label>
                        <Row className="g-2">
                          <Col md={6}>
                            <InputGroup className="my-2">
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Range"
                                value={
                                  el?.vehicle_age?.range?.split("-")[0] || ""
                                }
                                onChange={(e) =>
                                  handleTestOnChange(
                                    e.target.value,
                                    i,
                                    "vehicle_age",
                                    "range",
                                    1
                                  )
                                }
                              />

                              <InputGroup.Text>-</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Range"
                                value={el?.vehicle_age?.range?.split("-")[1] || ""}
                                onChange={(e) =>
                                  handleTestOnChange(e.target.value, i, "vehicle_age", "range", 2)
                                }
                              />
                            </InputGroup>
                            {((!el?.vehicle_age?.range?.split("-")[0] && el?.vehicle_age?.range?.split("-")[1]) || (el?.vehicle_age?.range?.split("-")[0] && !el?.vehicle_age?.range?.split("-")[1])) && validated && (
                              <div className="rs-invalid-feedback">Both range must be filled</div>
                            )}
                            {((el?.vehicle_age?.range?.split("-")[0] && el?.vehicle_age?.range?.split("-")[1]) && (Number(el?.vehicle_age?.range?.split("-")[0]) >= Number(el?.vehicle_age?.range?.split("-")[1]))) &&
                              validated && (<div>Start range must be less than to end range.</div>)
                            }
                          </Col>
                          <Col md={3}>
                            <Form.Control
                              type="text"
                              name="title"
                              className="my-2"
                              value={el?.vehicle_age?.greater_than_equal}
                              onChange={(e) => handleTestOnChange(e.target.value, i, "vehicle_age", "greater_than_equal")}
                              placeholder="Greater (>)"
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Control
                              type="text"
                              name="title"
                              className="my-2"
                              value={el?.vehicle_age?.less_than_equal}
                              onChange={(e) =>
                                handleTestOnChange(
                                  e.target.value,
                                  i,
                                  "vehicle_age",
                                  "less_than_equal"
                                )
                              }
                              placeholder="Less (<)"
                            />
                          </Col>

                          {/* {((!el?.vehicle_age?.range?.split("-")[0] || !el?.vehicle_age?.range?.split("-")[1]) && !el?.vehicle_age?.greater_than_equal && !el?.vehicle_age?.less_than_equal) &&
                            validated && (<div>Please select one: greater than, less than, or a range from start to end.</div>)
                          } */}
                        </Row>
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Expiry Days</Form.Label>
                        <Row className="g-2">
                          <Col md={6}>
                            <InputGroup className="my-2">
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Range"
                                value={
                                  el?.expiry_days?.range?.split("-")[0] || ""
                                }
                                onChange={(e) =>
                                  handleTestOnChange(
                                    e.target.value,
                                    i,
                                    "expiry_days",
                                    "range",
                                    1
                                  )
                                }
                              />

                              <InputGroup.Text>-</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Range"
                                value={
                                  el?.expiry_days?.range?.split("-")[1] || ""
                                }
                                onChange={(e) =>
                                  handleTestOnChange(
                                    e.target.value,
                                    i,
                                    "expiry_days",
                                    "range",
                                    2
                                  )
                                }
                              />
                            </InputGroup>
                            {((!el?.expiry_days?.range?.split("-")[0] && el?.expiry_days?.range?.split("-")[1]) || (el?.expiry_days?.range?.split("-")[0] && !el?.expiry_days?.range?.split("-")[1])) && validated && (
                              <div className="rs-invalid-feedback">Both range must be filled</div>
                            )}
                            {((el?.expiry_days?.range?.split("-")[0] && el?.expiry_days?.range?.split("-")[1]) && (Number(el?.expiry_days?.range?.split("-")[0]) >= Number(el?.expiry_days?.range?.split("-")[1]))) &&
                              validated && (<div>Start range must be less than to end range.</div>)
                            }
                          </Col>
                          <Col md={3}>
                            <Form.Control
                              type="text"
                              name="title"
                              className="my-2"
                              value={el?.expiry_days?.greater_than_equal}
                              onChange={(e) =>
                                handleTestOnChange(
                                  e.target.value,
                                  i,
                                  "expiry_days",
                                  "greater_than_equal"
                                )
                              }
                              placeholder="Greater (>)"
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Control
                              type="text"
                              name="title"
                              className="my-2"
                              value={el?.expiry_days?.less_than_equal}
                              onChange={(e) =>
                                handleTestOnChange(
                                  e.target.value,
                                  i,
                                  "expiry_days",
                                  "less_than_equal"
                                )
                              }
                              placeholder="Less (<)"
                            />
                          </Col>
                          {/* {((!el?.expiry_days?.range?.split("-")[0] || !el?.expiry_days?.range?.split("-")[1]) && !el?.expiry_days?.greater_than_equal && !el?.expiry_days?.less_than_equal) &&
                            validated && (<div>Please select one: greater than, less than, or a range from start to end.</div>)
                          } */}
                        </Row>
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Expired Days</Form.Label>
                        <Row className="g-2">
                          <Col md={6}>
                            <InputGroup className="my-2">
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Range"
                                value={
                                  el?.expired_days?.range?.split("-")[0] || ""
                                }
                                onChange={(e) =>
                                  handleTestOnChange(
                                    e.target.value,
                                    i,
                                    "expired_days",
                                    "range",
                                    1
                                  )
                                }
                              />

                              <InputGroup.Text>-</InputGroup.Text>
                              <Form.Control
                                type="text"
                                name="title"
                                placeholder="Range"
                                value={
                                  el?.expired_days?.range?.split("-")[1] || ""
                                }
                                onChange={(e) =>
                                  handleTestOnChange(
                                    e.target.value,
                                    i,
                                    "expired_days",
                                    "range",
                                    2
                                  )
                                }
                              />
                            </InputGroup>
                            {((!el?.expired_days?.range?.split("-")[0] && el?.expired_days?.range?.split("-")[1]) || (el?.expired_days?.range?.split("-")[0] && !el?.expired_days?.range?.split("-")[1])) && validated && (
                              <div className="rs-invalid-feedback">Both range must be filled</div>
                            )}
                            {((el?.expired_days?.range?.split("-")[0] && el?.expired_days?.range?.split("-")[1]) && (Number(el?.expired_days?.range?.split("-")[0]) >= Number(el?.expired_days?.range?.split("-")[1]))) &&
                              validated && (<div>Start range must be less than to end range.</div>)
                            }
                          </Col>
                          <Col md={3}>
                            <Form.Control
                              type="text"
                              name="title"
                              className="my-2"
                              value={el?.expired_days?.greater_than_equal}
                              onChange={(e) =>
                                handleTestOnChange(
                                  e.target.value,
                                  i,
                                  "expired_days",
                                  "greater_than_equal"
                                )
                              }
                              placeholder="Greater (>)"
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Control
                              type="text"
                              name="title"
                              className="my-2"
                              value={el?.expired_days?.less_than_equal}
                              onChange={(e) => handleTestOnChange(e.target.value, i, "expired_days", "less_than_equal")}
                              placeholder="Less (<)"
                            />
                          </Col>
                          {/* {((!el?.expired_days?.range?.split("-")[0] || !el?.expired_days?.range?.split("-")[1]) && !el?.expired_days?.greater_than_equal && !el?.expired_days?.less_than_equal) &&
                            validated && (<div>Please select one: greater than, less than, or a range from start to end.</div>)
                          } */}
                        </Row>
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Manufacturing Year</Form.Label>
                        <Select
                          cleanable={false}
                          options={manufactureYear}
                          value={el?.manufuring_year && manufactureYear?.filter((e) => el?.manufuring_year?.toString()?.split(",")?.some((id) => id == e.value))}
                          onChange={(e) => handleTestOnChange(e, i, "manufuring_year")}
                          block
                          isMulti
                          className={`customMulSelect my-2`}
                          classNamePrefix="react-select"
                          placeholder="Select Manufacturing Year"
                        />
                        {/* {!el?.manufuring_year && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a Manufuring Year.
                          </div>
                        )} */}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Vehicle Maker</Form.Label>
                        <CreatableSelect
                          isClearable
                          options={makerDropDown}
                          isMulti
                          placeholder="Select Vehicle Maker"
                          value={el?.maker && 
                            // (el?.is_bikeOrCar == 1 ? makerDropDown?.bike : makerDropDown?.car)
                            makerDropDown?.filter((e) => el?.maker?.split(",")?.some((id) => id == e.value))}
                          onChange={(e) => handleTestOnChange(e, i, "maker")}
                          className="customMulSelect my-2"
                          classNamePrefix="react-select"
                          isDisabled={el?.is_bikeOrCar == ''}
                        />
                        {/* {!el?.maker && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a Vehicle maker.
                          </div>
                        )} */}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Vehicle Modal</Form.Label>
                        <CreatableSelect
                          isClearable
                          options={modalDropDown}
                          isMulti
                          value={el?.model &&
                            //  (el?.is_bikeOrCar == 1 ? modalDropDown?.bike : modalDropDown?.car)
                            modalDropDown?.filter((e) => el?.model?.split(",")?.some((id) => id == e.value))}
                          onChange={(e) => handleTestOnChange(e, i, "model")}
                          placeholder="Select Vehicle Modal"
                          className="customMulSelect my-2"
                          classNamePrefix="react-select"
                          isDisabled={el?.is_bikeOrCar == ''}
                        />
                        {/* {!el?.model && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a Vehicle model.
                          </div>
                        )} */}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label className="me-2">City Name</Form.Label>
                        <Form.Check
                          inline
                          label="All City"
                          name="cityname"
                          type="radio"
                          id="city1"
                          checked={el?.city_id_list && el?.city_id_list?.toString()?.toLowerCase() == "all"}
                          onChange={(e) => handleTestOnChange(e.target.checked, i, "is_allCity")}
                        />
                        <Form.Check
                          inline
                          label="Specify City"
                          name="cityname"
                          type="radio"
                          id="city2"
                          checked={el?.city_id_list?.toString()?.toLowerCase() != "all"}
                          onChange={(e) => handleTestOnChange(e.target.checked, i, "is_specify_city")}
                        />
                        {el?.city_id_list?.toString()?.toLowerCase() != "all" && (
                          <>
                            <Select
                              closeMenuOnSelect={false}
                              name="state_name"
                              isClearable={true}
                              placeholder="Select State"
                              className={`customMulSelect my-2`}
                              // onChange={(e) => handleServiceChange(e, i, "state_list")}
                              onChange={(e) => handleStateChange(e, i, selectedState)}
                              classNamePrefix="react-select"
                              isMulti
                              // value={el?.city_id_list?.toString()?.toLowerCase() && el?.city_id_list?.toString()?.toLowerCase() != "all" && citys?.filter((e) =>
                              //   el?.city_id_list?.split(',')?.some((id) => id == e.value)
                              // )}
                              value={selectedState}
                              options={states}
                            />
                            <Select
                              closeMenuOnSelect={false}
                              name="city_name"
                              isClearable={true}
                              placeholder="Select City"
                              className={`customMulSelect my-2`}
                              onChange={(e) => handleTestOnChange(e, i, "city_id_list")}
                              classNamePrefix="react-select"
                              isMulti
                              value={el?.city_id_list?.toString()?.toLowerCase() && el?.city_id_list?.toString()?.toLowerCase() != "all" && citys?.filter((e) =>
                                el?.city_id_list?.toString()?.split(',')?.some((id) => id == e.value)
                              )}
                              options={citys}
                            />
                            {el?.city_id_list?.toString()?.toLowerCase() != "all" && el?.city_id_list?.toString()?.toLowerCase()?.length <= 0 &&
                              validated && (
                                <div className="rs-invalid-feedback">
                                  Please select a specify city.
                                </div>
                              )}
                            <div className="my-2">
                              <Form.Label>Block Pincode List</Form.Label>
                              <Form.Control
                                onChange={(e) => handleTestOnChange(e.target.value, i, "blocked_pincode")}
                                value={el?.blocked_pincode || ''}
                                type="text"
                                className="my-2"
                              />
                            </div>
                          </>
                        )}
                      </Col>
                      <Col lg={3} md={4}>
                        <Form.Label>Previous Insurance</Form.Label>
                        <SelectPicker
                          cleanable={true}
                          data={rsdata}
                          block
                          value={el?.previous_insurer}
                          onChange={(e) =>
                            handleTestOnChange(e, i, "previous_insurer")
                          }
                          className="my-2"
                          placeholder="Select Previous Insurance"
                        />
                        {/* {!el?.previous_insurer && validated && (
                          <div className="rs-invalid-feedback">
                            Please select a Previous Insurance.
                          </div>
                        )} */}
                      </Col>
                      <Col lg={1} md={4}>
                        <Form.Label>Status</Form.Label>
                        <Form.Check type="switch" className="my-2" checked={el?.status == 1} onChange={(e) => handleTestOnChange(e.target.checked, i, 'status')} />
                      </Col>
                      <Col
                        lg={1}
                        md={4}
                        className="align-self-end my-2 text-end"
                      >

                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() =>
                            handleDelete(
                              i,
                              el?.id && el?.id,
                              el?.new_id && el?.new_id
                            )
                          }
                          className="btn-icon-lg ms-3"
                        >
                          <i className="bx bx-x"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Form>
          );
        })}
      {/* {el?.services && el?.is_bikeOrCar == ''} */}
      {/* <Col sm={12}>
        <Card className="border">
          <Card.Header className="border-bottom">
            <h5 className="m-0 fw-bold">Default Value</h5>
          </Card.Header>
          <Card.Body>
            <Form id="default">
              <Row>
                <Col lg={3} md={4}>
                  <Form.Label>Services Provider</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={serviceProvider}
                    block
                    value={defaultValue?.service_provider || ""}
                    onChange={(e) => handleDefault(e, "service_provider")}
                    className="my-2"
                    placeholder="Select Services Provider"
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Services</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={service}
                    block
                    value={defaultValue?.services || ""}
                    onChange={(e) =>
                      // handleDefault(e, "services")
                      handleDefaultService(e, 'services', defaultValue?.is_bikeOrCar)

                    }
                    className="my-2"
                    placeholder="Select Services"
                  />
                  {
                    defaultValue?.services && !defaultValue?.is_bikeOrCar && (
                      <div className="rs-invalid-feedback">
                        Please select a valid service
                      </div>
                    )
                  }
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Priority</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={rsoptions}
                    block
                    value={defaultValue?.priority || ""}
                    onChange={(e) => handleDefault(e, "priority")}
                    className="my-2"
                    placeholder="Select Priority"
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Vehicle Type</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={[
                      { value: 1, label: "Private" },
                      { value: 2, label: "Commercial" },
                    ]}
                    block
                    value={defaultValue?.vehicle_type || ""}
                    onChange={(e) => handleDefault(e, "vehicle_type")}
                    className="my-2"
                    placeholder="Select Vehicle Type"
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Vehicle Age</Form.Label>
                  <Row className="g-2">
                    <Col md={6}>
                      <InputGroup className="my-2">
                        <Form.Control
                          type="text"
                          name="title"
                          value={
                            defaultValue?.vehicle_age?.range?.split("-")[0] ||
                            ""
                          }
                          onChange={(e) =>
                            handleDefault(
                              e.target.value,
                              "vehicle_age",
                              "range",
                              1
                            )
                          }
                          placeholder="Range"
                        />
                        <InputGroup.Text>-</InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="title"
                          value={
                            defaultValue?.vehicle_age?.range?.split("-")[1] ||
                            ""
                          }
                          onChange={(e) =>
                            handleDefault(
                              e.target.value,
                              "vehicle_age",
                              "range",
                              2
                            )
                          }
                          placeholder="Range"
                        />
                      </InputGroup>
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        type="text"
                        name="title"
                        className="my-2"
                        value={
                          defaultValue?.vehicle_age?.greater_than_equal || ""
                        }
                        onChange={(e) =>
                          handleDefault(
                            e.target.value,
                            "vehicle_age",
                            "greater_than_equal"
                          )
                        }
                        placeholder="Greater (>)"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        type="text"
                        name="title"
                        className="my-2"
                        value={defaultValue?.vehicle_age?.less_than_equal || ""}
                        onChange={(e) =>
                          handleDefault(
                            e.target.value,
                            "vehicle_age",
                            "less_than_equal"
                          )
                        }
                        placeholder="Less (<)"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Expiry Days</Form.Label>
                  <Row className="g-2">
                    <Col md={6}>
                      <InputGroup className="my-2">
                        <Form.Control
                          type="text"
                          name="title"
                          value={
                            defaultValue?.expiry_days?.range?.split("-")[0] ||
                            ""
                          }
                          onChange={(e) =>
                            handleDefault(
                              e.target.value,
                              "expiry_days",
                              "range",
                              1
                            )
                          }
                          placeholder="Range"
                        />
                        <InputGroup.Text>-</InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="title"
                          value={
                            defaultValue?.expiry_days?.range?.split("-")[1] ||
                            ""
                          }
                          onChange={(e) =>
                            handleDefault(
                              e.target.value,
                              "expiry_days",
                              "range",
                              2
                            )
                          }
                          placeholder="Range"
                        />
                      </InputGroup>
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        type="text"
                        name="title"
                        value={
                          defaultValue?.expiry_days?.greater_than_equal || ""
                        }
                        onChange={(e) =>
                          handleDefault(
                            e.target.value,
                            "expiry_days",
                            "greater_than_equal"
                          )
                        }
                        className="my-2"
                        placeholder="Greater (>)"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        type="text"
                        name="title"
                        value={defaultValue?.expiry_days?.less_than_equal || ""}
                        onChange={(e) => handleDefault(e.target.value, "expiry_days", "less_than_equal")}
                        className="my-2"
                        placeholder="Less (<)"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Expired Days</Form.Label>
                  <Row className="g-2">
                    <Col md={6}>
                      <InputGroup className="my-2">
                        <Form.Control
                          type="text"
                          name="title"
                          value={defaultValue?.expired_days?.range?.split("-")[0] || ""}
                          onChange={(e) => handleDefault(e.target.value, "expired_days", "range", 1)}
                          placeholder="Range"
                        />
                        <InputGroup.Text>-</InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="title"
                          value={
                            defaultValue?.expired_days?.range?.split("-")[1] ||
                            ""
                          }
                          onChange={(e) =>
                            handleDefault(
                              e.target.value,
                              "expired_days",
                              "range",
                              2
                            )
                          }
                          placeholder="Range"
                        />
                      </InputGroup>
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        type="text"
                        name="title"
                        className="my-2"
                        value={
                          defaultValue?.expired_days?.greater_than_equal || ""
                        }
                        onChange={(e) =>
                          handleDefault(
                            e.target.value,
                            "expired_days",
                            "greater_than_equal"
                          )
                        }
                        placeholder="Greater (>)"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        type="text"
                        name="title"
                        value={
                          defaultValue?.expired_days?.less_than_equal || ""
                        }
                        onChange={(e) =>
                          handleDefault(
                            e.target.value,
                            "expired_days",
                            "less_than_equal"
                          )
                        }
                        className="my-2"
                        placeholder="Less (<)"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Manufuring Year</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={manufactureYear}
                    block
                    value={defaultValue?.manufuring_year || ""}
                    onChange={(e) => handleDefault(e, "manufuring_year")}
                    className="my-2"
                    placeholder="Select Manufuring Year"
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Vehicle Maker</Form.Label>
                  <CreatableSelect
                    isClearable
                    options={makerDropDown?.bike}
                    isMulti
                    value={
                      defaultValue?.maker &&
                      makerDropDown?.bike?.filter((e) =>
                        defaultValue?.maker
                          ?.split(",")
                          ?.some((id) => id == e.value)
                      )
                    }
                    onChange={(e) => handleDefault(e, "maker")}
                    placeholder="Select Vehicle Maker"
                    className="customMulSelect my-2"
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Vehicle Modal</Form.Label>
                  <CreatableSelect
                    isClearable
                    options={modalDropDown?.bike}
                    isMulti
                    placeholder="Select Vehicle Modal"
                    className="customMulSelect my-2"
                    value={
                      defaultValue?.model &&
                      modalDropDown?.bike?.filter((e) =>
                        defaultValue?.model
                          ?.split(",")
                          ?.some((id) => id == e.value)
                      )
                    }
                    onChange={(e) => handleDefault(e, "model")}
                    classNamePrefix="react-select"
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label className="me-2">City Name</Form.Label>
                  <Form.Check
                    inline
                    label="All City"
                    name="cityname"
                    type="radio"
                    id="city1"
                    checked={defaultValue?.is_allCity}
                    onChange={(e) =>
                      handleDefault(e.target.checked, "is_allCity")
                    }
                  />
                  <Form.Check
                    inline
                    label="Specify City"
                    name="cityname"
                    type="radio"
                    id="city2"
                    checked={defaultValue?.is_specify_city}
                    onChange={(e) =>
                      handleDefault(e.target.checked, "is_specify_city")
                    }
                  />
                  {defaultValue?.is_specify_city && (
                    <Select
                      closeMenuOnSelect={false}
                      name="city_name"
                      isClearable={true}
                      placeholder="Select City"
                      className={`customMulSelect my-2`}
                      classNamePrefix="react-select"
                      isMulti
                      value={
                        defaultValue?.specify_city &&
                        citys?.filter((e) =>
                          defaultValue?.specify_city
                            ?.split(",")
                            ?.some((id) => id == e.value)
                        )
                      }
                      onChange={(e) => handleDefault(e, "specify_city")}
                      options={citys}
                      required={defaultValue?.is_specify_city}
                    />
                  )}
                  <div className="my-2">
                    <Form.Label>Block Pincode List</Form.Label>
                    <Form.Control
                      onChange={(e) => handleDefault(e.target.value, "blocked_pincode")}
                      value={defaultValue?.blocked_pincode || ''}
                      type="text"
                      className="my-2"
                    />
                  </div>
                </Col>

                <Col lg={3} md={4}>
                  <Form.Label>Previous Insurance</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={rsdata}
                    block
                    value={defaultValue?.previous_insurer}
                    onChange={(e) => handleDefault(e, "previous_insurer")}
                    className="my-2"
                    placeholder="Select Previous Insurance"
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Form.Label>Status</Form.Label>
                  <Form.Check type="switch" className="my-2" checked={false} />
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col> */}
    </>
  );
}

export default AddAffilation;
